<template>
  <div :class="['product-authors', { 'is-dots': data.length > maxAuthors && isShowDots }]">
    <div class="product-authors__items">
      <span
        v-for="author in data.slice(0, maxAuthors)"
        :key="author?.urlAlias"
        class="product-authors__item"
      >
        <LocaleLink
          v-if="isLink"
          :to="`/authors/${author?.urlAlias}`"
        >
          {{ author.label }}
        </LocaleLink>

        <span v-else> {{ author.label }} </span>
      </span>
    </div>

    <span
      v-if="data.length > maxAuthors && isShowMore"
      class="product-authors__more"
      @click.prevent="openAuthorsList"
    >
      {{ $t('allAuthors') }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { LocaleLink } from '@shared/LocaleLink';

interface IAuthor {
  urlAlias: string;
  label: string;
}

interface IProps {
  data: IAuthor[];
  isLink?: boolean;
  maxAuthors?: number;
  isShowMore?: boolean;
  isShowDots?: boolean;
}

withDefaults(defineProps<IProps>(), {
  maxAuthors: 2,
});

const $emit = defineEmits(['open:list']);

const openAuthorsList = () => {
  $emit('open:list');
};
</script>

<style lang="scss" src="./ProductAuthors.scss" />
