<template>
  <button
    type="button"
    :class="['favorite-btn', classComputed]"
    :aria-label="isActive ? $t('ariaLabel.removeToWishlist') : $t('ariaLabel.addToWishlist')"
    @click="isActive ? removeProductWishlist() : addProductWishlist()"
  >
    <Icon
      v-if="!isRemove"
      class="favorite-btn__icon"
      :name="isActive ? 'favorite-active' : 'favorite'"
    />
    <Icon
      v-else
      class="favorite-btn__icon"
      name="close"
    />
    <span
      v-if="isText"
      class="favorite-btn__text"
    >
      {{ $t('addToFavorites') }}
    </span>
  </button>
</template>

<script lang="ts" setup>
import { Icon } from '@shared/Icon';

interface Props {
  data: any;
  isText?: boolean;
  isRemove?: boolean;
  variant?: 'round' | 'square' | 'simple';
}

const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  variant: 'square',
});

const { openAuthModal } = useAuth();

const { addWishlist, removeWishlist } = useWishlist();

const { t: $t } = useI18n();

const { isAuth } = storeToRefs(useAuthStore());

const { wishlist } = storeToRefs(useWishlistStore());

const pending = ref(false);

const wishlistItem = computed(() => wishlist.value?.items?.find((item: any) => item.product.sku === props.data?.sku));
const isActive = computed(() => !!wishlistItem.value);

const classComputed = computed(() => {
  return [
    {
      'is-loading': pending.value,
      'is-active': isActive.value,
      'is-remove': props.isRemove,
    },
    props.variant && `favorite-btn--${props.variant}`,
  ];
});

/**
 * Adds a product to the wishlist.
 * Opens the authentication modal if the user is not authenticated.
 * @returns {Promise<void>}
 */
const addProductWishlist = async (): Promise<void> => {
  if (!isAuth.value) {
    return openAuthModal(addProductWishlist);
  }

  pending.value = true;

  try {
    await addWishlist(props.data);
  } finally {
    pending.value = false;
  }
};

/**
 * Removes a product from the wishlist.
 * @returns {Promise<void>}
 */
const removeProductWishlist = async (): Promise<void> => {
  pending.value = true;

  try {
    await removeWishlist({ ...props.data, wishlistItemId: wishlistItem.value.id });
  } finally {
    pending.value = false;
  }
};
</script>

<style lang="scss" src="./FavoriteButton.scss" />
