export const useWishlist = () => {
  const { trackAddToWishlist } = useAnalytics();

  const { isAuth } = storeToRefs(useAuthStore());
  const { wishlist } = storeToRefs(useWishlistStore());

  const getWishlist = async ({ wishlistId }: { wishlistId: number }) => {
    try {
      const result = await fetchApiGet('/wishlist', {
        data: {
          wishlistId,
        },
      });

      wishlist.value = result?.wishlist;

      return result;
    } catch (error: any) {
      throw createError(error);
    }
  };

  const addWishlist = async (product: any) => {
    if (!isAuth.value || !wishlist.value) {
      return false;
    }

    try {
      const result = await fetchApiPost('/wishlist/add', {
        data: {
          wishlistId: wishlist.value?.wishlistId,
          sku: product?.sku,
        },
      });

      const attributes = product?.attributes?.items ?? product?.attributes ?? [];
      const brand = attributes?.find((item: any) => item.code === 'publisher')?.selectedOptions?.[0]?.label;
      const author = attributes?.find((item: any) => item.code === 'author')?.selectedOptions?.[0]?.label;

      trackAddToWishlist({
        value: product?.priceRange?.minimumPrice?.finalPrice?.value,
        items: [
          {
            item_id: product?.sku,
            item_name: product?.name,
            item_brand: brand,
            item_author: author,
            price: product?.priceRange?.minimumPrice?.finalPrice?.value,
            quantity: 1,
          },
        ],
      });

      wishlist.value = result?.wishlist;
    } catch (error: any) {
      throw createError(error);
    }
  };

  const removeWishlist = async (product: any) => {
    if (!isAuth.value || !product?.id) {
      return false;
    }

    try {
      const result = await fetchApiDelete('/wishlist/remove', {
        data: {
          wishlistId: wishlist.value?.wishlistId,
          itemId: product?.wishlistItemId,
        },
      });

      wishlist.value = result?.wishlist;
    } catch (error: any) {
      throw createError(error);
    }
  };

  return {
    getWishlist,
    addWishlist,
    removeWishlist,
  };
};
